img.profile {
  width: 450px;
  border-radius: 20px;
  margin-bottom: 20px;
  margin: 0 40px;
}

div.bio {
  text-align: justify;
  line-height: 32px;
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

p {
  margin-top: 30px;
  font-size: 15px;
  line-height: 20px;
}
.about-me {
  text-align: center;
  font-size: 30px;
  padding-bottom: 20px;
  font-weight: bold;
}
.about-page {
  background-color: rgb(10, 10, 10);
  display: flex;
  padding: 100px;
  padding-top: 80px;
  justify-content: center;
  height: 100vh;
}

.desc {
  margin: 0 50px 0 30px;
  font-size: 18px;
}
@media screen and (max-width: 1024px) {
  /* .desc {
    margin-right: 0px;
    margin-left: 0px;
  }
  .about-page {
    height: 100vh;
    padding: 50px;
    margin-top: 20px;
  } */
  img.profile {
    margin-left: 0;
    margin-right: 40px;
    width: 50vw;
  }
}

@media screen and (max-width: 480px) {
  img.profile {
    width: 70vw;
    margin-right: 0px;
  }

  .about-page {
    display: flex;
    flex-direction: column;
    padding: 0px;
    padding-bottom: 100px;
  }
  div.bio {
    margin-top: 10px;
    text-align: justify;
    line-height: 32px;
    width: 100vw;
  }
  .desc {
    margin: 0 20px 0 20px;
    font-size: 18px;
  }
  p {
    margin-top: 20px;
    font-size: 15px;
    line-height: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
