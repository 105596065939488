.social {
  width: 20vh;
  filter: invert(100);
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 20px;
}

.contact-page {
  display: flex;
  justify-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100vh;
}

.contact {
  margin: 50px 0 60px 0;
  font-size: 50px;
}

.socials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.social2 {
  margin-left: 25px;
  margin-right: 25px;
}

@media screen and (max-width: 1024px) {
  img.social {
    width: 10vh;
  }

  .socail2 {
    width: 105vh;
  }
}

@media screen and (max-width: 480px) {
  .socials {
    flex-direction: column;
  }
  img.social {
    width: 10vh;
    margin-bottom: 40px;
  }
  .contact-page {
    height: 100vh;
  }
}
