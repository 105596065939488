.project-img {
  width: 30vw;
  /* height: fit-content; */
  height: 40vh;
  /* display: none; */
  border-radius: 20px;
  margin: 0;
}
.work-page {
  height: 100vh;
}

.container {
  background-color: #181818;
  padding-top: 10px;
  padding-bottom: 10px;
  /* height: 70vh; */
  border-radius: 20px;
  display: flex;

  width: 95vw;
  align-items: center;
  margin: 0 auto;
  margin-top: 30px;
}

.projects {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.project {
  padding: 20px;
  background-color: rgb(10, 10, 10);
  width: 60vw;
  height: 60vh;
  margin-left: 80px;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  /* overflow-y: scroll; */
}

.project-title,
.brief {
  color: rgb(10, 10, 10);
}

.brief {
  text-align: left;
  /* overflow-y: scroll; */
}

.button-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 8px;
  transition: all 0.3s ease-in-out;
}

.button-container:hover {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.deploy,
.github {
  background-color: black;
  border-style: none;
  padding: 10px;
  border-radius: 5px;
}

.project:hover {
  padding: 20px;
  background-color: rgb(255, 255, 255);
  width: 60vw;
  height: 77vh;
  margin-left: 80px;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1024px) {
  .projects {
    width: 100vw;
  }
  .project-img {
    width: 60vw;
    height: 40vh;
  }
  .project {
    height: 50vh;
    width: 62vw;
  }
  .project:hover {
    height: 60vh;
  }
}

@media screen and (max-width: 480px) {
  .work-page {
    height: 355vh;
    width: 100vw;
  }
  .projects {
    flex-direction: column;
    justify-content: center;
    overflow-x: none;
    overflow-y: none;
  }

  .project {
    margin-left: 10px;
    margin-right: 0px;
    width: 85vw;
    height: 75vh;
    background-color: rgb(255, 255, 255);
    margin-bottom: 30px;
    padding: 10px;
  }

  .project:hover {
    padding: 10px;
    width: 85vw;
    height: 75vh;
    margin-left: 10px;
    transition: all 0.3s ease-in-out;
  }
  .container {
    /* height: 100vh; */
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .project-img {
    width: 80vw;
  }
  .brief {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
