h1 {
  margin: 0;
}

.body {
  height: 100vh;
  transition: all 0.2s ease-in-out;

  /* background-image: linear-gradient(red, yellow); */
}

.hero-box {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  transition: all 0.2s ease-in-out;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
}

.title,
.name {
  font-size: 2.2em;
  line-height: 160%;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  z-index: 2;
}

.sub-title {
  font-size: 1.2em;
  line-height: 30%;
  font-weight: 300;
}
.name {
  font-size: 5.5em;
  font-family: "Jost", sans-serif;
  line-height: 100px;
  /* text-shadow: 2px 2px #7c7c7c; */
  /* color: rgb(73, 173, 255); */
  text-shadow: 2px 2px #003ee9;
  z-index: 2;
  transition: ease-in-out 0.3s;
}

.title2::before {
  border-right: 1px solid white;
}

.title2 {
  animation: type 3s steps(18);
  overflow: hidden;
  white-space: nowrap;

  width: 18ch;
  border-right: 1px solid rgba(255, 255, 255, 0.329);
}
.title2::after {
  border-right: 0px;
}

.skill-text {
  animation: blinking 1s infinite;
}

@keyframes blinking {
  0%,
  100% {
    border-right: 2px solid transparent;
    margin-left: 2px;
  }
  100% {
    border-right: 2px solid white;
  }
}
@keyframes type {
  0% {
    width: 0ch;
  }
  100% {
    width: 18ch;
  }
}

.load {
  position: relative;
  bottom: 360px;
  /* width: 200px; */
  width: 500px;

  opacity: 0.5;
}

.arrow:hover {
  color: rgb(73, 173, 255);
  transition: ease-in-out 0.2s;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1024px) {
  .title2 {
    animation: type 3s steps(19);
    font-size: 26px;
    width: 19ch;
  }
  .skill-text {
    animation: blinking 1s infinite;
  }
  @keyframes blinking {
    0%,
    100% {
      border-right: 2px solid transparent;
      margin-left: 2px;
    }
    100% {
      border-right: 2px solid white;
    }
  }
  @keyframes type {
    0% {
      width: 0ch;
    }
    100% {
      width: 19ch;
    }
  }
}

@media screen and (max-width: 480px) {
  .title2,
  .title {
    letter-spacing: 0px;
    font-size: 27px;
  }
  .name {
    font-size: 5em;
  }
  .title2 {
    animation: type 3s steps(19);
    font-size: 26px;
    width: 19ch;
  }
  .skill-text {
    animation: blinking 1s infinite;
  }
  @keyframes blinking {
    0%,
    100% {
      border-right: 2px solid transparent;
      margin-left: 2px;
    }
    100% {
      border-right: 2px solid white;
    }
  }
  @keyframes type {
    0% {
      width: 0ch;
    }
    100% {
      width: 19ch;
    }
  }
}
