.nav {
  padding: 2rem;
}

.list {
  list-style-type: none;
  margin: 0px;
  display: flex;
  justify-content: flex-end;
  max-width: 90%;
}

li {
  display: inline;
  padding-left: 100px;
  text-decoration: none;
}
a {
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in-out;
  position: relative;
}
a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(73, 173, 255);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
a:hover {
  color: rgb(73, 173, 255);
  transition: ease-in-out 0.2s;
  /* text-decoration: underline; */
  transform: scaleX(1);
}

@media screen and (max-width: 480px) {
  li {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
  }
  .list {
    max-width: 100vw;
    justify-content: space-around;
    padding-left: 0px;
    margin: 0px;
  }
}
